<template>
  <div class="project">
    <div class="loading" v-if="loading">
      <h3>...</h3>
    </div>
    <div v-if="error" class="error">
      {{ error }}
    </div>
    <div class="content" v-if="content">
      <div class="title">
        <router-link :to="{ name: 'Home' }">
          <h1>{{ content.title }}</h1>
        </router-link>
      </div>
      <div class="images" v-if="content.media" :class="mq.current">
        <div class="set" v-for="(set, index) in content.media" :key="index">
          <div class="image" v-if="set.image">
            <img :src="imgurl(set.image).width(1500)" />
          </div>
          <div class="video" v-if="set.video">
            <img
              @load="videoHeight($event.target)"
              :src="'http://img.youtube.com/vi/' + set.video + '/hqdefault.jpg'"
              alt=""
            />
            <div class="play" @click="loadVideo(set.video, index)"
><div class="play-icon"></div></div>
            <iframe
              src=""
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <!-- video -->
          <div class="caption">
            <h3>{{ set.caption }}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sanity from "../client";
import imageUrlBuilder from "@sanity/image-url";

const imageBuilder = imageUrlBuilder(sanity);
const query = `*[slug.current == $slug] {
  title,
  media,
  slug,
}[0]`;
export default {
  inject: ["mq"],
  data() {
    return {
      content: [],
    };
  },
  created() {
    this.fetchData();
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    fetchData() {
      this.error = this.post = null;
      this.loading = true;

      sanity.fetch(query, { slug: this.$route.params.slug }).then(
        (content) => {
          this.loading = false;
          this.content = content;
        },
        (error) => {
          this.error = error;
        }
      );
    },
    imgurl(source) {
      return imageBuilder.image(source);
    },
    onResize() {
      let allVids = document.querySelectorAll(".video");
      if (allVids.length > 0) {
        allVids.forEach((item) => {
          let vidW = item.offsetWidth;
          item.style.height = vidW * 0.5625 + "px";
        });
      }
    },
    videoHeight(event) {
      let video = event.parentElement.offsetWidth;
      event.parentElement.style.height = video * 0.5625 + "px";
    },
    loadVideo(id, index) {
      let item = document.querySelectorAll(".set")[index]
      let frame = item.querySelector("iframe");
      frame.src =
        "https://www.youtube.com/embed/" + id + "?autoplay=1&controls=0";
      item.querySelector('.video img').style.display = "none";
      item.querySelector('.play').style.display = "none";
    },
  },
};
</script>

<style lang="scss">
.project {
  margin: 25px;
  width: calc(100% - 50px);

  .title {
    padding-top: 1px;
    padding-bottom: 30px;
  }

  .images {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding-top: 50px;

    .set {
      width: 100%;

      &:last-child {
        .caption {
          min-height: auto;
        }
      }
    }

    .video {
      width: 100%;
      max-width: 1000px;
      position: relative;

      img {
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 100%;
        object-fit: cover;
        cursor: pointer;
      }

      .play {
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .play-icon {
          height: 0;
          border-top: 35px solid transparent;
          border-bottom: 35px solid transparent;
          border-left: 60px solid #01ff76;
        }
      }

      iframe {
        position: absolute;
        left: 0;
        width: 100% !important;
        height: 100%;
      }
    }

    &.xs .set {
      width: 100%;
      justify-content: flex-start;

      .play-icon {
        border-top: 21px solid transparent;
        border-bottom: 21px solid transparent;
        border-left: 36px solid #01ff76;
      }
    }

    img {
      width: 100%;
      max-width: 1000px;
      padding: 0;
    }

    .caption {
      width: 100%;
      max-width: 1000px;
      text-align: right;
      min-height: 100px;
    }
  }
}
</style>
